import React, { useEffect, useState } from "react";
import { useTheme, useToast } from "@chakra-ui/react";
import { get } from "lodash";
import { navigate } from "gatsby";
import { Field, Form as FinalForm } from "react-final-form";
import toastMessage from "../../../utilities/toastMessage";
import Box from "../../base/Box";
import { required } from "../../../validations";
import FormError from "../../base/FormError";
import Button from "../../base/Button";
import Route from "../../base/Route";
import Section from "../../base/Section";
import Heading from "../../base/Heading";
import { get as GET, post } from "../../../utilities/http";
import Dropdown from "../../base/Dropdown";
import { STATUS } from "../../../utilities/constants";

const SimAssociationAddForm = ({ location }) => {
  const theme = useTheme();
  const toast = useToast();
  const gap = 7;
  const [nodeDropDownOptions, setNodeDropDownOptions] = useState([]);
  const [simDropDownOptions, setSimDropDownOptions] = useState([]);
  const simAssociation = get(location, "state.simAssociation", null);
  const colStyled = {
    flex: 1,
    fontSize: 1,
    maxWidth: "60%",
    mb: gap,
    mx: 4,
    width: "50%",
  };
  const colFull = {
    minWidth: "100%",
  };
  useEffect(async () => {
    try {
      const { data } = await GET("/available-nodes");
      const dropDownData = data.map((node) => ({
        label: `#${get(node, "node_id")} - ${get(node, "name")}`,
        value: get(node, "node_id"),
      }));
      setNodeDropDownOptions(dropDownData);
    } catch (e) {
      toastMessage({ message: e.message, toast, type: "error" });
    }
  }, []);
  useEffect(async () => {
    try {
      const { data } = await GET("/available-sims");
      const dropDownData = data.map((node) => ({
        label: `#${get(node, "sim_id")} - ${get(node, "isdn")}`,
        value: get(node, "sim_id"),
      }));
      setSimDropDownOptions(dropDownData);
    } catch (e) {
      toastMessage({ message: e.message, toast, type: "error" });
    }
  }, []);
  return (
    <Route isPrivate layout="admin">
      <Section>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            maxWidth: ["90%", null, null, "70%"],
            mb: 4,
            mx: "auto",
          }}
        >
          <Heading {...theme.variant.heading}>Añadir nueva asociación</Heading>
        </Box>
        <Box
          sx={{
            bg: "white",
            borderRadius: 4,
            maxWidth: ["90%", null, "70%"],
            mx: "auto",
            p: 10,
          }}
        >
          <FinalForm
            initialValues={{
              node: get(simAssociation, "node_id"),
              sim: get(simAssociation, "sim_id"),
            }}
            onSubmit={async (values) => {
              try {
                const payload = {
                  node_id: values.node,
                  sim_id: values.sim,
                };

                const data = await post("/add-sim-assoc", payload);

                const message =
                  data && get(data, "success")
                    ? get(data, "msm")
                    : "Association Added!";
                if (data) {
                  toastMessage({
                    message,
                    toast,
                    type:
                      data && get(data, "success") === STATUS.INACTIVE
                        ? "error"
                        : "success",
                  });
                }
                navigate("/simAssociation");
              } catch (e) {
                toastMessage({
                  message: e.message,
                  toast,
                  type: "error",
                });
              }
            }}
            render={(formContext) => (
              <form onSubmit={formContext.handleSubmit}>
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Box
                    as={Field}
                    component={Dropdown}
                    disabled={!!simAssociation}
                    name="node"
                    options={nodeDropDownOptions}
                    placeholder="Nodos"
                    sx={{
                      "::placeholder": {
                        color: "grays.3",
                        fontSize: 2,
                      },
                      width: "100%",
                    }}
                    validate={required}
                    wrapperSx={{ ...colStyled, fontSize: 2, mx: "auto" }}
                  />
                  <Box
                    as={Field}
                    component={Dropdown}
                    disabled={!!simAssociation}
                    name="sim"
                    options={simDropDownOptions}
                    placeholder="SIMs"
                    sx={{
                      "::placeholder": {
                        color: "grays.3",
                        fontSize: 2,
                      },
                      width: "100%",
                    }}
                    validate={required}
                    wrapperSx={{ ...colStyled, fontSize: 2, mx: "auto" }}
                  />
                  <FormError>{formContext.submitError}</FormError>
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      my: 5,
                      ...colFull,
                    }}
                  >
                    <Button
                      disabled={formContext.pristine || formContext.submitting}
                      submitting={formContext.submitting}
                      sx={{ mr: 3, width: "130px" }}
                      type="submit"
                      variant="primary"
                    >
                      Guardar
                    </Button>
                    <Button
                      onClick={() => {
                        navigate("/simAssociation");
                      }}
                      sx={{ ml: 3, width: "130px" }}
                    >
                      Cancelar
                    </Button>
                  </Box>
                </Box>
              </form>
            )}
          />
        </Box>
      </Section>
    </Route>
  );
};

export default SimAssociationAddForm;
