import React from "react";
import { Router } from "@reach/router";

import SimAssociationListingPage from "../components/PageComponents/SimAssociationListingPage";
import SimAssociationAddPage from "../components/PageComponents/SimAssociationAddPage";

const simAssociation = (props) => (
  <Router>
    <SimAssociationListingPage {...props} path="/simAssociation" />
    <SimAssociationAddPage
      {...props}
      path="/simAssociation/add-simAssociation"
    />
  </Router>
);

export default simAssociation;
